<template>
  <page-layout>
    <template
      v-slot:topBar
    >
      <search-top-bar
        @submit="submitSearch"
        :loading="loading"
        :allow-add-before-search="false"
      />
    </template>
    <search-page-body
      @search-from-history="searchFromHistory"
      :search-history="searchHistory"
    >
      {{ messages.getLabelSearchForStock() }}
    </search-page-body>
  </page-layout>
</template>

<script>
import { messages } from '@/utils/strings'
import SearchPageBody from '../components/Search/SearchPageBody'
import SearchTopBar from '../components/Search/SearchTopBar'
import PageLayout from '../components/PageLayout'
import { stockManagementSearchHistory } from '@/utils/search-history'
import { MOBILE_MAX_RESULTS } from '@/constants/shared-constants'

export default {
  name: 'SearchStockManagement',
  components: { PageLayout, SearchPageBody, SearchTopBar },
  data () {
    return {
      loading: false,
      messages
    }
  },
  created () {
    this.$emit('set-background-color', '#F4F4F4')
  },
  computed: {
    uid () {
      return this.$store.state.profile.uid
    },
    searchHistory: function () {
      // This most likely does not automatically be recalculated when
      // addSearchToHistory is called. However since the view changes
      // back to StockManagement, when the user navigates back to this
      // view this computed property will get triggered and updated.
      return stockManagementSearchHistory.retrieve()
    }
  },
  methods: {
    submitSearch (searchValue) {
      const queryParameters = {
        query: searchValue?.[0]?.split(' ') ?? [],
        maxResults: MOBILE_MAX_RESULTS
      }
      this.$store.dispatch('stockManagement/search', queryParameters)
      // Go to the results page (Main Stock Management page) before the response arrives
      // to get rid of annoying delay caused by route change. I don't know why that
      // delay is there.
      this.$router.push({ name: 'StockManagement' })
    },
    /**
     * Use text showed in badges and stored in local storage for searching
     *
     * @param {Object} searchEntry -
     */
    searchFromHistory (searchEntry) {
      const queryParameters = {
        query: searchEntry.searchText.split(' '),
        maxResults: MOBILE_MAX_RESULTS
      }
      this.$store.dispatch('stockManagement/search', queryParameters)
      // Go to the results page (Main Stock Management page) before the response arrives
      // to get rid of annoying delay caused by route change. I don't know why that
      // delay is there.
      this.$router.push({ name: 'StockManagement' })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
